import * as React from 'react';
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import FormField from './FormField';

import styles from './TextArea.module.css';

type Props = {
  allowResize: boolean;
  autoGrow: boolean;
  helpText?: string;
  label?: string;
  onChange: (value: string) => void;
  rows: number;
  showLineNumbers: boolean;
  value: string;
  placeholder?: string;
};

function TextArea(props: Props): JSX.Element {
  const { onChange, value } = props;
  const onValueChange = useCallback(
    (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange]
  );
  const lines = useMemo(() => value.split('\n'), [value]);

  return (
    <FormField label={props.label} helpText={props.helpText}>
      <div className={styles.container}>
        {props.showLineNumbers && (
          <div className={styles.line_numbers}>
            {lines.map((_, i) => (
              <span key={`line_${i}`}>
                {i + 1}
                <br />
              </span>
            ))}
          </div>
        )}
        <textarea
          className={classnames({
            [styles.textarea]: true,
            [styles.no_resize]: props.allowResize === false,
          })}
          rows={props.autoGrow ? lines.length : props.rows}
          value={props.value}
          onChange={onValueChange}
          placeholder={props.placeholder}
        />
      </div>
    </FormField>
  );
}

TextArea.defaultProps = {
  allowResize: true,
  autoGrow: false,
  rows: 5,
  showLineNumbers: false,
};

export default TextArea;
