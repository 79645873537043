import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSyncAlt,
  faSpinner,
  faRedoAlt,
  faCircleNotch,
  faCompactDisc,
  faFan,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { Center } from '../layout';
import config from 'config.json';

type Props = {
  type: 'single-arrow' | 'double-arrow' | 'dots' | 'circle' | 'disc' | 'fan';
  size: string;
  padding: string;
  margin: string;
};

/**
 * A loading spinner.
 */
function Loading({ type, size, padding, margin }: Props): JSX.Element {
  const icon = useMemo(() => {
    switch (type) {
      case 'single-arrow':
        return faRedoAlt;
      case 'double-arrow':
        return faSyncAlt;
      case 'circle':
        return faCircleNotch;
      case 'dots':
        return faSpinner;
      case 'disc':
        return faCompactDisc;
      case 'fan':
        return faFan;
    }
  }, [type]);

  return (
    <FontAwesomeIcon
      icon={icon}
      spin={true}
      style={{
        fontSize: size,
        padding,
        margin,
      }}
    />
  );
}

Loading.defaultProps = {
  type: config.default_loading_spinner_style || 'fan',
  size: 'inherit',
  padding: '0.5rem',
  margin: '0',
};

/**
 * A loading spinner that's larger than Loading and center-aligned within its container.
 */
export function PageLoading(): JSX.Element {
  return (
    <Center horizontal={true} vertical={true}>
      <Loading size="2.5rem" />
    </Center>
  );
}

export default Loading;
