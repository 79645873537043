import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyDaikWOEnAARJpwoJNWDRgfN2GR7ghHU3w',
  authDomain: 'resume-editor-40fa5.firebaseapp.com',
  databaseURL: 'https://resume-editor-40fa5.firebaseio.com',
  projectId: 'resume-editor-40fa5',
  storageBucket: 'resume-editor-40fa5.appspot.com',
  messagingSenderId: '722463679738',
  appId: '1:722463679738:web:6180189b35bc9667029543',
  measurementId: 'G-JLZBXF2GWN',
};

firebase.initializeApp(config);

export const auth = firebase.auth;
export const db = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();
