import * as React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import styles from './NoticeCard.module.css';
import { useMemo } from 'react';

type Props = {
  type: 'info' | 'warning' | 'success' | 'error';
  title?: string;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  overrideIcon?: IconDefinition;
};

function NoticeCard({
  type,
  title,
  children,
  footerContent,
  overrideIcon,
}: Props): JSX.Element {
  const icon = useMemo(() => {
    switch (type) {
      case 'info':
        return faInfoCircle;
      case 'success':
        return faCheckCircle;
      case 'warning':
        return faExclamationTriangle;
      case 'error':
        return faExclamationCircle;
    }
  }, [type]);

  return (
    <div
      className={classnames({
        [styles.info]: type === 'info',
        [styles.warning]: type === 'warning',
        [styles.success]: type === 'success',
        [styles.error]: type === 'error',
        [styles.container]: true,
      })}
    >
      <div className={styles.icon}>
        <FontAwesomeIcon icon={overrideIcon ?? icon} />
      </div>
      <div className={styles.content}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {children}

        {footerContent && <div className={styles.footer}>{footerContent}</div>}
      </div>
    </div>
  );
}

export default NoticeCard;
