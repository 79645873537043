import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import FormField from './FormField';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Select.module.css';
import Checkbox from './Checkbox';
import classNames from 'classnames';
import { Center } from '../layout';
import { Text } from '../text';

type Props = {
  label?: string;
  helpText?: string;
  options: Array<{
    header?: string;
    items: Array<{
      label: React.ReactNode;
      value: string;
    }>;
  }>;
  selectedItems: Array<string>;
  onChange: (selectedItems: Array<string>) => void;
};

function MultiSelect({
  label,
  helpText,
  options,
  selectedItems,
  onChange,
}: Props): JSX.Element {
  const [shouldShowOptions, setShouldShowOptions] = useState(false);

  return (
    <FormField label={label} helpText={helpText}>
      <div className={styles.container}>
        <div
          className={classnames({
            [styles.selector]: true,
            [styles.is_expanded]: shouldShowOptions,
          })}
        >
          <div
            className={styles.selector_content}
            onClick={() => {
              setShouldShowOptions(!shouldShowOptions);
            }}
          >
            <div>
              {selectedItems.length} item{selectedItems.length === 1 ? '' : 's'}{' '}
              selected
            </div>
            <FontAwesomeIcon
              icon={faCaretDown}
              className={classnames({
                [styles.arrow]: true,
                [styles.is_expanded]: shouldShowOptions,
              })}
            />
          </div>
        </div>

        {shouldShowOptions && (
          <div
            className={classNames({
              [styles.options]: true,
              [styles.multi_select_options]: true,
            })}
          >
            {options.map((option, i) => (
              <div key={`o_${option.header}_${i}`}>
                {option.header && (
                  <div className={styles.section_header}>{option.header}</div>
                )}

                {option.items.length === 0 && (
                  <Center horizontal>
                    <Text size="xsmall" color="subtle">
                      No items
                    </Text>
                  </Center>
                )}

                {option.items.map((item) => (
                  <Checkbox
                    key={item.value}
                    label={item.label}
                    checked={selectedItems.includes(item.value)}
                    onChange={(checked) => {
                      if (checked) {
                        onChange([...selectedItems, item.value]);
                      } else {
                        onChange(selectedItems.filter((v) => v !== item.value));
                      }
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </FormField>
  );
}

export default MultiSelect;
