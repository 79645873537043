import classNames from 'classnames';
import * as React from 'react';

import styles from './Card.module.css';

function Card(props: {
  className?: string;
  children: React.ReactNode;
  title?: string;
  headerRightContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  highlight?: boolean;
  showHoverEffect?: boolean;
  onClick?: () => void;
}): JSX.Element {
  return (
    <div
      className={classNames({
        [styles.card]: true,
        [props.className ?? '']: true,
        [styles.highlighted]: props.highlight,
        [styles.hover_effect]: props.showHoverEffect,
        [styles.clickable]: props.onClick != null,
      })}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      {(props.title != null || props.headerRightContent != null) && (
        <div className={styles.header_container}>
          {props.title && <h2 className={styles.title}>{props.title}</h2>}
          {props.headerRightContent && (
            <div className={styles.header_right_content}>
              {props.headerRightContent}
            </div>
          )}
        </div>
      )}

      <div>{props.children}</div>
      {props.footerContent && (
        <div className={styles.footer}>{props.footerContent}</div>
      )}
    </div>
  );
}

export default Card;
