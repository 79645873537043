import * as React from 'react';
import { LoginForm } from 'prima/auth/components';

import styles from './LoginPage.module.css';
import { NoticeCard } from 'prima/ui/containers';

type Props = {};

function LoginPage(props: Props): JSX.Element {
  return (
    <div className={styles.login_container}>
      <NoticeCard type="info" title="Login required">
        <LoginForm postLoginRedirectHref="/editor" />
      </NoticeCard>
    </div>
  );
}

export default LoginPage;
