import * as React from 'react';
import Modal from './Modal';
import { Button } from '../forms';
import { Paragraph } from '../text';

type Props = {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  cancelLabel: string;
  confirmLabel: string;
  showSpinner: boolean;
};

function ConfirmationDialog(props: Props): JSX.Element {
  return (
    <Modal
      title={props.title}
      footerContent={
        <>
          <Button
            label={props.cancelLabel}
            onClick={props.onCancel}
            disabled={props.showSpinner}
          />
          <Button
            label={props.confirmLabel}
            onClick={props.onConfirm}
            primary={true}
            showSpinner={props.showSpinner}
          />
        </>
      }
    >
      <Paragraph>{props.message}</Paragraph>
    </Modal>
  );
}

ConfirmationDialog.defaultProps = {
  confirmLabel: 'Yes',
  cancelLabel: 'No',
  showSpinner: false,
};

export default ConfirmationDialog;
