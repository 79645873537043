import React, { useEffect, useMemo, useState, Suspense, lazy } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { auth } from './services/firebase';
import firebase from 'firebase';
import RemoteConfigClient from 'prima/remote_config/RemoteConfigClient';
import { Center } from 'prima/ui/layout';
import { Loading } from 'prima/ui/spinners';

import LoginPage from './pages/LoginPage';

import './App.css';

const MainPage = lazy(() => import('./pages/MainPage'));
const ResumePage = lazy(() => import('./pages/ResumePage'));

RemoteConfigClient.initialise();

function RedirectToLinkedIn() {
  window.location.href = 'https://www.linkedin.com/in/subeeshb/';
  return null;
}

function App() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
  }, []);

  const authenticated = useMemo(() => !!user, [user]);

  if (loading) {
    return (
      <Center horizontal={true} vertical={true}>
        <Loading size="2.5rem" />
      </Center>
    );
  }

  return (
    <Router>
      <Suspense
        fallback={
          <Center horizontal={true} vertical={true}>
            <Loading size="2.5rem" />
          </Center>
        }
      >
        <Switch>
          <Route
            exact={true}
            path="/"
            render={(props) =>
              authenticated === false ? (
                <RedirectToLinkedIn />
              ) : (
                <Redirect to="/editor" />
              )
            }
          />
          <Route
            exact={true}
            path="/login"
            render={(props) =>
              authenticated === false ? (
                <LoginPage />
              ) : (
                <Redirect to="/editor" />
              )
            }
          />
          <Route
            path="/editor"
            render={(props) =>
              authenticated === true ? (
                <MainPage />
              ) : (
                <Redirect
                  to={{ pathname: '/login', state: { from: props.location } }}
                />
              )
            }
          />
          <Route path="/:resumeID" component={ResumePage} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
